import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Home from './components/home/home';
import Login from './components/login/login';
import { useStore } from './stores';
import { ROUTE } from './util/route';
import { Navigate, NavigateFunction, Outlet, Route, Routes, redirect, useMatch, useNavigate } from 'react-router';
import EventsRoute from './components/routes/events/events';
import { DateTime, Settings } from 'luxon';
import { Event } from './types/event';
import { SIDEBAR_POSITION } from './components/sidebar/sidebar';
import CustomersRoute, { CustomersRouteSearchForm } from './components/routes/customers/customers';
import OrdersRoute, { OrdersRouteSearchForm } from './components/routes/orders/orders';
import UserRoute from './components/routes/user/user';
import { Customer, CustomerWithLatestDetails } from './types/customer';
import { OrderWithDetails } from './types/order';
import { flowResult } from 'mobx';
import EventSidebar from './components/event-sidebar/event-sidebar';
import CustomerSidebar from './components/customer-sidebar/customer-sidebar';
import OrderSidebar from './components/order-sidebar/order-sidebar';

Settings.defaultLocale = 'es-GT';

declare global {
    interface Window {
        navigate: NavigateFunction;
    }
}

export default observer(function App() {
    const { sessionStore, eventStore, customerStore } = useStore();
    const { loggedInUserId } = sessionStore;
    const navigate = useNavigate();

    const isInLoginRoute = useMatch({ path: ROUTE.LOGIN, end: true }) !== null;

    const [startOfWeekDateTime, setStartOfWeekDateTime] = useState<DateTime>(DateTime.now().minus({ days: 3 }));
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerWithLatestDetails | null>(null);
    const [selectedOrder, setSelectedOrder] = useState<OrderWithDetails | null>(null);
    const [eventSidebarPosition, setEventSidebarPosition] = useState<SIDEBAR_POSITION>(SIDEBAR_POSITION.LEFT);
    const [customersRouteSearchForm, setCustomersRouteSearchForm] = useState<CustomersRouteSearchForm>({
        customer: null,
    });
    const [ordersRouteSearchForm, setOrdersRouteSearchForm] = useState<OrdersRouteSearchForm>({
        id: null,
        productName: null,
        billingStateId: null,
        status: null,
    });

    useEffect(() => {
        window.navigate = navigate;
    }, []);

    useEffect(() => {
        if (loggedInUserId !== null && isInLoginRoute) {
            navigate(ROUTE.EVENTS);
        } else if (loggedInUserId === null && !isInLoginRoute) {
            redirect(ROUTE.LOGIN);
        }
    }, [loggedInUserId]);

    const handleEventClick = (event: Event) => {
        const customAtDateTime = DateTime.fromISO(event.custom_at);
        const baseDayIndex = startOfWeekDateTime.weekday;
        const middleDayIndex = (baseDayIndex - 7 + (baseDayIndex - 1)) / 2;
        const dayIndex =
            customAtDateTime.weekday < baseDayIndex ? customAtDateTime.weekday : customAtDateTime.weekday - 7;
        setEventSidebarPosition(dayIndex <= middleDayIndex ? SIDEBAR_POSITION.RIGHT : SIDEBAR_POSITION.LEFT);
        setSelectedEvent(event);
    };

    const handleCustomerClick = (customer: CustomerWithLatestDetails) => {
        setSelectedCustomer(customer);
    };

    const handleOrderClick = (order: OrderWithDetails) => {
        setSelectedOrder(order);
    };

    const handleEventShow = (eventId: Event['id']) => {
        flowResult(eventStore.show(eventId)).then(({ data, successful }) => {
            if (successful && data) {
                if (!location.pathname.includes(ROUTE.EVENTS)) {
                    navigate(ROUTE.EVENTS);
                }
                handleEventClick({ ...data, latest: true });
            }
        });
    };

    const handleCustomerShow = (customerEmail: Customer['email']) => {
        flowResult(customerStore.show(customerEmail)).then(({ data, successful }) => {
            if (successful && data) {
                handleCustomerClick(data);
            }
        });
    };

    const handleCustomerChange = (customer: Partial<Customer> & Pick<Customer, 'email'>) => {
        flowResult(customerStore.update(customer)).then(({ data, successful }) => {
            if (successful && data) {
                if (selectedEvent) {
                    setSelectedEvent(selectedEvent => (selectedEvent ? { ...selectedEvent, customer: data } : null));
                    eventStore.setCustomer(data);
                } else if (selectedCustomer) {
                    setSelectedCustomer(selectedCustomer =>
                        selectedCustomer ? { ...selectedCustomer, ...data } : null
                    );
                    customerStore.setCustomer(data);
                }
            }
        });
    };

    const handleEventChange = (event: Partial<Event> & Pick<Event, 'id'>) => {
        flowResult(eventStore.update(event)).then(successful => {
            if (successful) {
                setSelectedEvent(selectedEvent => (selectedEvent ? { ...selectedEvent, ...event } : null));
            }
        });
    };

    const handleEventDeselect = () => {
        setSelectedEvent(null);
    };

    const handleCustomerDeselect = () => {
        setSelectedCustomer(null);
    };

    const handleOrderDeselect = () => {
        setSelectedOrder(null);
    };

    return (
        <>
            <Routes>
                <Route path="home" element={<Outlet />}>
                    <Route path={ROUTE.EVENTS} element={<Navigate to={`/${ROUTE.EVENTS}`} replace />} />
                    <Route path={ROUTE.CUSTOMERS} element={<Navigate to={`/${ROUTE.CUSTOMERS}`} replace />} />
                    <Route path={ROUTE.ORDERS} element={<Navigate to={`/${ROUTE.ORDERS}`} replace />} />
                    <Route path={ROUTE.USER} element={<Navigate to={`/${ROUTE.USER}`} replace />} />
                </Route>
                <Route index element={<Navigate to={ROUTE.EVENTS} replace />} />
                <Route path={ROUTE.LOGIN} element={<Login />} />
                <Route
                    element={
                        <Home
                            isBlurred={selectedEvent !== null || selectedCustomer !== null || selectedOrder !== null}
                            onEventDeselect={handleEventDeselect}
                            onCustomerDeselect={handleCustomerDeselect}
                            onOrderDeselect={handleOrderDeselect}
                        />
                    }
                >
                    <Route
                        path={ROUTE.EVENTS}
                        element={
                            <EventsRoute
                                startOfWeekDateTime={startOfWeekDateTime}
                                selectedEvent={selectedEvent}
                                onStartOfWeekDateTimeChange={setStartOfWeekDateTime}
                                onEventClick={handleEventClick}
                            />
                        }
                    />
                    <Route
                        path={ROUTE.CUSTOMERS}
                        element={
                            <CustomersRoute
                                searchForm={customersRouteSearchForm}
                                onCustomerClick={handleCustomerClick}
                                onLatestEventShow={handleEventShow}
                                onSearchFormChange={setCustomersRouteSearchForm}
                            />
                        }
                    />
                    <Route
                        path={ROUTE.ORDERS}
                        element={
                            <OrdersRoute
                                searchForm={ordersRouteSearchForm}
                                onOrderClick={handleOrderClick}
                                onEventShow={handleEventShow}
                                onSearchFormChange={setOrdersRouteSearchForm}
                            />
                        }
                    />
                    <Route path={ROUTE.USER} element={<UserRoute />} />
                    <Route path="/*" element={<Navigate to={`/${ROUTE.EVENTS}`} replace />} />
                </Route>
            </Routes>
            <EventSidebar
                event={selectedEvent}
                position={eventSidebarPosition}
                onCustomerChange={handleCustomerChange}
                onEventChange={handleEventChange}
                onLatestEventShow={handleEventShow}
                onClose={handleEventDeselect}
            />
            <CustomerSidebar
                customer={selectedCustomer}
                onCustomerChange={handleCustomerChange}
                onLatestEventShow={handleEventShow}
                onClose={handleCustomerDeselect}
            />
            <OrderSidebar
                order={selectedOrder}
                onEventShow={handleEventShow}
                onCustomerShow={handleCustomerShow}
                onClose={handleOrderDeselect}
            />
        </>
    );
});
