import { useResponsiveness } from '../../providers/responsiveness-provider';
import className from './footer.module.scss';
import { LuCopyright, LuHeart } from 'react-icons/lu';

export default function Footer() {
    const { isMobile } = useResponsiveness();

    return (
        <div className={className.base}>
            Banana-Pet <LuCopyright size={12} /> 2021 - {new Date().getFullYear()} • Hecho con <LuHeart size={12} /> por
            Henry Campos
            {!isMobile && ` • ${process.env.REACT_APP_GIT_COMMIT_SHA}`}
        </div>
    );
}
