import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { useStore } from '../../../stores';
import { UserForm } from '../../../types/user-form';
import { COLOR } from '../../../util/color';
import { ROUTE } from '../../../util/route';
import { useForm } from '../../../util/use-form';
import Button from '../../button/button';
import EmailInput from '../../email-input/email-input';
import Form from '../../form/form';
import PasswordInput from '../../password-input/password-input';
import SubmitButton from '../../submit-button/submit-button';
import TextInput from '../../text-input/text-input';
import UserImageInput from '../../user-image-input/user-image-input';
import className from './user.module.scss';
import { useNavigate } from 'react-router';

const DEFAULT_USER_FORM: UserForm = {
    id: -1,
    image_url: '',
    name: '',
    email: '',
    password: null,
};

export default observer(function UserRoute() {
    const { userStore, sessionStore } = useStore();
    const { user, isUpdating } = userStore;
    const { isLoggingOut } = sessionStore;

    const submitButtonRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const [
        userForm,
        isUserFormInvalid,
        isUserFormDirty,
        handleUserFormChange,
        handleUserFormValidnessChange,
        setUserFormResetHandler,
        isUserFormShown,
        ,
        setUserForm,
    ] = useForm(DEFAULT_USER_FORM, true);

    useEffect(() => {
        setUserForm({ ...(user || DEFAULT_USER_FORM), password: null });
    }, [user]);

    const handleSubmit = () => {
        userStore.update(userForm);
    };

    const handleLogoutButtonClick = () => {
        flowResult(sessionStore.logout()).then(successful => {
            if (successful) {
                navigate(ROUTE.LOGIN);
            }
        });
    };

    return (
        <div className={className.base}>
            <Form
                form={userForm}
                isInvalid={isUserFormInvalid}
                isShown={isUserFormShown}
                submitButton={{
                    isDisabled: isUserFormInvalid || !isUserFormDirty,
                    isLoading: isUpdating,
                    element: submitButtonRef.current,
                }}
                onChange={handleUserFormChange}
                onSubmit={handleSubmit}
                setFormResetHandler={setUserFormResetHandler}
                onValidnessChange={handleUserFormValidnessChange}
            >
                {inputProps => (
                    <>
                        <UserImageInput
                            {...inputProps}
                            className={className['user-image-input']}
                            valueKey="image_url"
                            user={user}
                        />
                        <TextInput {...inputProps} placeholder="Nombre" valueKey="name" isRequired={true} />
                        <EmailInput {...inputProps} valueKey="email" isRequired={true} />
                        <PasswordInput
                            {...inputProps}
                            placeholder="Nueva contraseña"
                            valueKey="password"
                            autoComplete="new-password"
                            needsRepetition={true}
                            onSubmit={handleSubmit}
                        />
                        <SubmitButton
                            ref={submitButtonRef}
                            className={className['submit-button']}
                            isDisabled={isUserFormInvalid || !isUserFormDirty}
                            isLoading={isUpdating}
                        >
                            Guardar
                        </SubmitButton>
                        <Button
                            className={className['logout-button']}
                            color={COLOR.RED_500}
                            onClick={handleLogoutButtonClick}
                            isLoading={isLoggingOut}
                        >
                            Cerrar sesión
                        </Button>
                    </>
                )}
            </Form>
        </div>
    );
});
