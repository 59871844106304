import { useEffect, useMemo, useState } from 'react';
import { Event } from '../../types/event';
import Button from '../button/button';
import { COLOR } from '../../util/color';
import { DateTime } from 'luxon';
import { useStore } from '../../stores';
import { observer } from 'mobx-react';
import OrderHistory from '../order-history/order-history';
import className from './event-sidebar.module.scss';
import cx from 'classnames';
import { Customer } from '../../types/customer';
import Sidebar, { SIDEBAR_POSITION } from '../sidebar/sidebar';
import SidebarDetail from '../sidebar/sidebar-detail';
import UserSelect from '../user-select/user-select';
import { useCallbackRef } from '../../util/use-callback-ref';
import { useForm } from '../../util/use-form';
import Form from '../form/form';
import TextArea from '../text-area/text-area';
import PhoneNumbers from '../phone-numbers/phone-numbers';
import { useLocalStorageState } from '../../util/use-local-storage-state';
import { LOCAL_STORAGE_KEY } from '../../util/local-storage-key';
import Collapsable from '../collapsable/collapsable';
import Badge from '../badge/badge';
import PetTypeSelect from '../pet-type-select/pet-type-select';
import { PET_TYPE } from '../../util/pet-type';
import DatePicker from '../date-picker/date-picker';
import Link from '../link/link';
import VerticalSpacer from '../vertical-spacer/vertical-spacer';
import EventIdFormatter from '../event-id-formatter/event-id-formatter';
import { flowResult } from 'mobx';
import RectangleContentLoader from '../content-loader/rectangle';
import { LuBell, LuChevronDown, LuCircleCheck, LuClock, LuX } from 'react-icons/lu';

interface EventForm {
    note: string | null;
}

interface EventSidebarProps {
    event: Event | null;
    position: SIDEBAR_POSITION;
    onCustomerChange: (customer: Partial<Customer> & Pick<Customer, 'email'>) => void;
    onEventChange: (event: Partial<Event> & Pick<Event, 'id'>) => void;
    onLatestEventShow: (eventId: Event['id']) => void;
    onClose: () => void;
}

const DEFAULT_EVENT_FORM: EventForm = {
    note: null,
};

export default observer(function EventSidebar(props: EventSidebarProps) {
    const { eventStore } = useStore();
    const { isUpdating } = eventStore;

    const [event, setEvent] = useState<Event | null>(null);
    const [latestEventId, setLatestEventId] = useState<Event['id'] | null>(null);
    const [topSectionHeight, setTopSectionHeight] = useState<number>(0);
    const [isCollapsed, setIsCollapsed] = useLocalStorageState<boolean>(
        LOCAL_STORAGE_KEY.IS_EVENT_SIDEBAR_COLLAPSED,
        value => value === 'true'
    );
    const [isDatePickerCollapsed, setIsDatePickerCollapsed] = useState<boolean>(true);

    const [topSectionRef, setTopSectionRef] = useCallbackRef<HTMLDivElement>(topSectionElement => {
        if (topSectionElement) {
            setTopSectionHeight(topSectionElement.getBoundingClientRect().height);
        }
    });

    const [
        eventForm,
        isEventFormInvalid,
        ,
        handleEventFormChange,
        handleEventFormValidnessChange,
        setEventFormResetHandler,
        isEventFormShown,
        ,
        setEventForm,
    ] = useForm(DEFAULT_EVENT_FORM, true);

    useEffect(() => {
        if (!event) {
            setIsDatePickerCollapsed(true);
            setLatestEventId(null);
        } else {
            if (!event.latest) {
                flowResult(eventStore.showLatestId(event.id)).then(({ data, successful }) => {
                    if (successful && data) {
                        setLatestEventId(data);
                    }
                });
            }
        }
    }, [event]);

    useEffect(() => {
        let timeoutId: number | undefined;

        if (!props.event) {
            timeoutId = window.setTimeout(() => {
                setEvent(null);
            }, 300);
        } else {
            if (
                !event ||
                props.event.id !== event.id ||
                props.event.completed !== event.completed ||
                props.event.custom_at !== event.custom_at ||
                props.event.customer.user_id !== event.customer.user_id ||
                props.event.customer.pet_type !== event.customer.pet_type
            ) {
                setEvent(props.event);
            }

            if (!event || props.event.note !== event.note) {
                setEventForm({ note: props.event.note });
            }
        }

        return () => window.clearTimeout(timeoutId);
    }, [props.event]);

    const handleAssignedUserChange = (userId: Customer['user_id'] | null) => {
        if (event && userId !== null) {
            props.onCustomerChange({ email: event.customer.email, user_id: userId });
        }
    };

    const handlePetTypeChange = (petType: PET_TYPE | null) => {
        if (event && petType !== null) {
            props.onCustomerChange({ email: event.customer.email, pet_type: petType });
        }
    };

    const handleCompletedChange = () => {
        if (event) {
            props.onEventChange({ id: event.id, completed: !event.completed });
        }
    };

    const isOverdue = useMemo(() => {
        return event ? DateTime.fromISO(event.custom_at) < DateTime.now().startOf('day') && !event.completed : false;
    }, [event]);

    const handleAutoSubmit = () => {
        if (event) {
            props.onEventChange({ id: event.id, note: eventForm.note });
        }
    };

    const handleCustomAtChange = (customAt: Event['custom_at'] | null) => {
        if (event && customAt) {
            props.onEventChange({ id: event.id, custom_at: customAt });
        }
    };

    useEffect(() => {
        const topSectionElement = topSectionRef.current;
        if (topSectionElement) {
            window.setTimeout(() => {
                setTopSectionHeight(topSectionElement.getBoundingClientRect().height);
            }, 300);
        }
    }, [isCollapsed]);

    const handleCollapseButtonClick = () => {
        setIsCollapsed(!isCollapsed);
    };

    const toggleDatePicker = () => {
        setIsDatePickerCollapsed(isDatePickerCollapsed => !isDatePickerCollapsed);
    };

    return (
        <Sidebar
            className={cx(className.base, {
                [className.completed]: event?.completed,
                [className.moved]: event?.at !== event?.custom_at && !event?.completed,
                [className.overdue]: isOverdue,
            })}
            position={props.position}
            isOpen={!!props.event}
            onClose={props.onClose}
        >
            {event && (
                <>
                    <div ref={setTopSectionRef} className={className['top-section']}>
                        <div className={className.header}>
                            <div className={className.title}>
                                <div className={className['completed-indicator']}>
                                    <LuCircleCheck />
                                </div>
                                <div className={className['overdue-indicator']}>
                                    <LuClock />
                                </div>
                                <div className={className['moved-indicator']}>
                                    <LuBell />
                                </div>
                                <div className={className.pet}>
                                    <div className={className.name}>
                                        <EventIdFormatter>{event.id}</EventIdFormatter>
                                    </div>
                                    <Badge>{event.days_between}d</Badge>
                                </div>
                            </div>
                            <div className={className['close-button']} onClick={props.onClose}>
                                <LuX />
                                <div className={className.label}>cerrar</div>
                            </div>
                        </div>
                        <SidebarDetail label="Cliente">{event.customer.name}</SidebarDetail>
                        <SidebarDetail label="Teléfono">
                            <PhoneNumbers>{event.customer.phone_number}</PhoneNumbers>
                        </SidebarDetail>
                        {!event.latest && (
                            <SidebarDetail label="Último recordatorio">
                                {latestEventId !== null ? (
                                    <Link
                                        onClick={() => {
                                            props.onClose();
                                            props.onLatestEventShow(latestEventId);
                                        }}
                                    >
                                        <EventIdFormatter>{latestEventId}</EventIdFormatter>
                                    </Link>
                                ) : (
                                    <RectangleContentLoader width={84} height={18} />
                                )}
                            </SidebarDetail>
                        )}
                        <Collapsable bodyClassName={className.details} collapsed={isCollapsed}>
                            <SidebarDetail label="Fecha">
                                <div className={className['custom-at']}>
                                    {DateTime.fromISO(event.custom_at).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                                    {!event.completed && event.latest && (
                                        <Link onClick={toggleDatePicker}>Recalendarizar</Link>
                                    )}
                                </div>
                                <Collapsable collapsed={isDatePickerCollapsed}>
                                    <VerticalSpacer size={12} />
                                    <DatePicker
                                        value={event.custom_at}
                                        isDisabledBefore={
                                            DateTime.fromISO(event.at) < DateTime.now()
                                                ? DateTime.now().toISODate()
                                                : event.at
                                        }
                                        onChange={handleCustomAtChange}
                                    />
                                </Collapsable>
                            </SidebarDetail>
                            {event.custom_at !== event.at && (
                                <SidebarDetail label="Fecha original">
                                    {DateTime.fromISO(event.at).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                                </SidebarDetail>
                            )}
                            <SidebarDetail label="Correo electrónico">{event.customer.email}</SidebarDetail>
                            <Form
                                className={className['event-form']}
                                form={eventForm}
                                isInvalid={isEventFormInvalid}
                                isShown={isEventFormShown}
                                onChange={handleEventFormChange}
                                setFormResetHandler={setEventFormResetHandler}
                                onValidnessChange={handleEventFormValidnessChange}
                                onAutoSubmit={handleAutoSubmit}
                            >
                                {inputProps => (
                                    <SidebarDetail label="Notas">
                                        <TextArea {...inputProps} isDisabled={!event.latest} valueKey="note" />
                                    </SidebarDetail>
                                )}
                            </Form>
                            <UserSelect
                                value={event.customer.user_id}
                                placeholder="Vendedora o vendedor"
                                isDisabled={!event.latest}
                                isRequired
                                onChange={handleAssignedUserChange}
                            />
                            <PetTypeSelect
                                value={event.customer.pet_type}
                                placeholder="Tipo de mascota"
                                isDisabled={!event.latest}
                                isRequired
                                onChange={handlePetTypeChange}
                            />
                        </Collapsable>
                        <Button
                            className={className['complete-button']}
                            color={event.completed ? undefined : COLOR.BLUE_500}
                            isLoading={isUpdating}
                            onClick={handleCompletedChange}
                            isDisabled={!event.latest}
                        >
                            {event.completed ? 'Desmarcar como contactado' : 'Marcar como contactado'}
                        </Button>
                        <div
                            className={cx(className['collapse-button'], { [className.collapsed]: isCollapsed })}
                            onClick={handleCollapseButtonClick}
                        >
                            <LuChevronDown /> {isCollapsed ? 'Expandir detalles' : 'Colapsar detalles'}
                        </div>
                    </div>
                    <OrderHistory
                        customerEmail={event.customer.email}
                        height={`calc(100% - ${topSectionHeight + 40}px)`}
                    />
                </>
            )}
        </Sidebar>
    );
});
