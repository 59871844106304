import { Event } from '../../../types/event';
import className from './event-card.module.scss';
import cx from 'classnames';
import Collapsable from '../../collapsable/collapsable';
import { DateTime } from 'luxon';
import Badge from '../../badge/badge';
import EventIdFormatter from '../../event-id-formatter/event-id-formatter';
import { ForwardedRef, forwardRef } from 'react';
import { LuBell, LuCircleCheck, LuClock } from 'react-icons/lu';

interface EventCardProps {
    event: Event;
    isShown: boolean;
    className?: string;
    id?: string;
    onClick: (event: Event) => void;
}

const EventCard = forwardRef(function (props: EventCardProps, ref: ForwardedRef<HTMLDivElement>) {
    const handleClick = () => {
        props.onClick(props.event);
    };

    return (
        <Collapsable collapsed={!props.isShown} className={props.className} id={props.id}>
            <div
                ref={ref}
                className={cx(className.base, {
                    [className.completed]: props.event.completed,
                    [className.moved]: props.event.at !== props.event.custom_at && !props.event.completed,
                    [className.overdue]:
                        (DateTime.fromISO(props.event.custom_at) < DateTime.now().startOf('day') &&
                            !props.event.completed) ||
                        (!props.event.completed && !props.event.latest),
                })}
                onClick={handleClick}
            >
                <div className={className.header}>
                    <div className={className['completed-indicator']}>
                        <LuCircleCheck />
                    </div>
                    <div className={className['overdue-indicator']}>
                        <LuClock />
                    </div>
                    <div className={className['moved-indicator']}>
                        <LuBell />
                    </div>
                    <div className={className.id}>
                        <EventIdFormatter>{props.event.id}</EventIdFormatter>
                    </div>
                    <Badge>{props.event.days_between}d</Badge>
                </div>
                {props.event.latest && (
                    <>
                        <div className={className.client}>{props.event.customer.name}</div>
                        <div className={className['phone-number']}>{props.event.customer.phone_number}</div>
                    </>
                )}
            </div>
        </Collapsable>
    );
});

export default EventCard;
