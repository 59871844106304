import { useMemo } from 'react';
import { Order } from '../../types/order';
import getOrderStatusLabel from '../../util/get-order-status-label';
import { ORDER_STATUS } from '../../util/order-status';
import Badge, { BADGE_COLOR } from '../badge/badge';

interface OrderStatusProps {
    children: Order['status'];
}

export default function OrderStatus(props: OrderStatusProps) {
    const orderStatus = useMemo(() => props.children, [props.children]);

    const badgeColor = useMemo(() => {
        switch (orderStatus) {
            case ORDER_STATUS.COMPLETED:
                return BADGE_COLOR.BLUE;
            case ORDER_STATUS.CANCELLED:
                return BADGE_COLOR.GRAY;
            case ORDER_STATUS.PROCESSING:
                return BADGE_COLOR.GREEN;
            case ORDER_STATUS.FAILED:
                return BADGE_COLOR.RED;
            case ORDER_STATUS.ON_HOLD:
                return BADGE_COLOR.YELLOW;
        }
    }, [orderStatus]);

    return <Badge color={badgeColor}>{getOrderStatusLabel(orderStatus)}</Badge>;
}
