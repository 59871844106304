import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router';
import App from './app';
import ContextMessage from './components/context-message/context-message';
import './index.scss';
import ResponsivenessProvider from './providers/responsiveness-provider';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/error-boundary/error-boundary';

const root = createRoot(document.getElementById('⚛')!);

root.render(
    <StrictMode>
        <ResponsivenessProvider>
            <ErrorBoundary fallback={<>Error...</>}>
                <Router>
                    <ContextMessage />
                    <App />
                </Router>
            </ErrorBoundary>
        </ResponsivenessProvider>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
