import { ORDER_STATUS } from './order-status';

const getOrderStatusLabel = (orderStatus: ORDER_STATUS) => {
    switch (orderStatus) {
        case ORDER_STATUS.COMPLETED:
            return 'Completado';
        case ORDER_STATUS.CANCELLED:
            return 'Cancelado';
        case ORDER_STATUS.PROCESSING:
            return 'Procesando';
        case ORDER_STATUS.FAILED:
            return 'Fallido';
        case ORDER_STATUS.ON_HOLD:
            return 'En espera';
    }
};

export default getOrderStatusLabel;
