import className from './order-summary.module.scss';
import cx from 'classnames';
import { Order } from '../../types/order';
import { DateTime, Settings } from 'luxon';
import Badge from '../badge/badge';
import RectangleContentLoader from '../content-loader/rectangle';
import { useStore } from '../../stores';
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import OrderStatus from '../order-status/order-status';

export enum ORDER_SUMMARY_FORMAT {
    DEFAULT = 'default',
    SIMPLE = 'simple',
}

interface OrderSummaryProps {
    className?: string;
    order: Order | null;
    isOrderIdHidden?: boolean;
    format?: ORDER_SUMMARY_FORMAT;
}

function OrderSummary(props: OrderSummaryProps) {
    const { billingStateStore } = useStore();
    const { mappedBillingStates } = billingStateStore;

    const format = useMemo(() => props.format || ORDER_SUMMARY_FORMAT.DEFAULT, [props.format]);

    const billingState = useMemo(
        () => (props.order ? mappedBillingStates[props.order.billing_state_id] : null),
        [mappedBillingStates, props.order]
    );

    const renderProducts = () => {
        return (
            <div className={className.products}>
                {(props.order ? props.order.products : new Array(2).fill(null)).map(
                    (product: Order['products'][0] | null, index) => {
                        const [quantity, description] = product ? product : [];

                        return (
                            <div key={`${className.product}-${index}`} className={className.product}>
                                {product ? (
                                    <>
                                        <Badge>x{quantity}</Badge>
                                        <div>{description}</div>
                                    </>
                                ) : (
                                    <>
                                        <RectangleContentLoader width={24} height={20} />
                                        <RectangleContentLoader width={100} height={12} />
                                    </>
                                )}
                            </div>
                        );
                    }
                )}
            </div>
        );
    };

    return (
        <div className={cx(className['order-summary'], props.className, className[format])}>
            {format === ORDER_SUMMARY_FORMAT.DEFAULT ? (
                <div className={className.header}>
                    <div className={className.date}>
                        {props.order ? (
                            <>
                                {DateTime.fromISO(props.order.at).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
                                {props.order.pet_name && (
                                    <div className={className['pet-name']}>
                                        para <b>{props.order.pet_name}</b>
                                    </div>
                                )}
                            </>
                        ) : (
                            <RectangleContentLoader width={168} height={13} />
                        )}
                    </div>
                    {props.order ? (
                        <Badge>
                            {(props.order.total / 100).toLocaleString(Settings.defaultLocale, {
                                style: 'currency',
                                currency: 'GTQ',
                            })}
                        </Badge>
                    ) : (
                        <RectangleContentLoader width={56} height={20} />
                    )}
                </div>
            ) : null}
            {props.order && (
                <div className={className.details}>
                    {!props.isOrderIdHidden && (
                        <div className={className.detail}>
                            <div className={className.label}>Pedido</div>#{props.order.id}
                        </div>
                    )}
                    <div className={className.detail}>
                        <div className={className.label}>Estado</div> <OrderStatus>{props.order.status}</OrderStatus>
                    </div>
                    {format === ORDER_SUMMARY_FORMAT.SIMPLE && (
                        <>
                            <div className={className.detail}>
                                <div className={className.label}>Fecha</div>
                                {DateTime.fromISO(props.order.at).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
                            </div>
                            {props.order.pet_name && (
                                <div className={className.detail}>
                                    <div className={className.label}>Mascota</div>
                                    {props.order.pet_name}
                                </div>
                            )}
                            <div className={className.detail}>
                                <div className={className.label}>Total</div>
                                <Badge>
                                    {(props.order.total / 100).toLocaleString(Settings.defaultLocale, {
                                        style: 'currency',
                                        currency: 'GTQ',
                                    })}
                                </Badge>
                            </div>
                        </>
                    )}
                    {!!props.order.shipping_total && (
                        <div className={className.detail}>
                            <div className={className.label}>Precio de envío</div>
                            <Badge>
                                {(props.order.shipping_total / 100).toLocaleString(Settings.defaultLocale, {
                                    style: 'currency',
                                    currency: 'GTQ',
                                })}
                            </Badge>
                        </div>
                    )}
                    {props.order.note && (
                        <div className={className.detail}>
                            <div className={className.label}>Nota</div>
                            {props.order.note}
                        </div>
                    )}
                    {props.order.billing.name && (
                        <div className={className.detail}>
                            <div className={className.label}>Nombre en factura</div>
                            {props.order.billing.name}
                        </div>
                    )}
                    {props.order.billing.tin && (
                        <div className={className.detail}>
                            <div className={className.label}>NIT</div>
                            {props.order.billing.tin}
                        </div>
                    )}
                    {props.order.billing.address && (
                        <div className={className.detail}>
                            <div className={className.label}>Dirección</div>
                            {props.order.billing.address}
                        </div>
                    )}
                    {billingState && (
                        <div className={className.detail}>
                            <div className={className.label}>Lugar</div>
                            {billingState.name}
                        </div>
                    )}
                    {format === ORDER_SUMMARY_FORMAT.SIMPLE && (
                        <div className={className.detail}>
                            <div className={className.label}>Productos</div>
                            {renderProducts()}
                        </div>
                    )}
                </div>
            )}
            {format === ORDER_SUMMARY_FORMAT.DEFAULT && renderProducts()}
        </div>
    );
}

export default observer(OrderSummary);
