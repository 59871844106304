import { useRef } from 'react';
import { useResponsiveness } from '../../providers/responsiveness-provider';
import { useStore } from '../../stores';
import { ROUTE } from '../../util/route';
import { useForm } from '../../util/use-form';
import Form from '../form/form';
import Image from '../image/image';
import PasswordInput from '../password-input/password-input';
import SubmitButton from '../submit-button/submit-button';
import className from './login.module.scss';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import EmailInput from '../email-input/email-input';
import { useNavigate } from 'react-router';

interface LoginForm {
    email: string;
    password: string;
}

const DEFAULT_LOGIN_FORM: LoginForm = {
    email: '',
    password: '',
};

export default observer(function Login() {
    const { sessionStore } = useStore();
    const { isLoggingIn } = sessionStore;

    const responsiveness = useResponsiveness();
    const navigate = useNavigate();

    const submitButtonRef = useRef<HTMLDivElement>(null);

    const [
        loginForm,
        isLoginFormInvalid,
        ,
        handleLoginFormChange,
        handleLoginFormValidnessChange,
        setLoginFormResetHandler,
        isLoginFormShown,
    ] = useForm(DEFAULT_LOGIN_FORM, true);

    const handleSubmit = () => {
        flowResult(sessionStore.login(loginForm.email.trim(), loginForm.password)).then(successful => {
            if (successful) {
                navigate(ROUTE.EVENTS);
            }
        });
    };

    return (
        <div className={className.base}>
            <Image
                className={className.logo}
                url="/logo.png"
                isLocal={true}
                width={responsiveness.isMobile ? 300 : 600}
                height={responsiveness.isMobile ? 101 : 202}
            />
            <Form
                form={loginForm}
                isInvalid={isLoginFormInvalid}
                isShown={isLoginFormShown}
                submitButton={{
                    isDisabled: isLoginFormInvalid,
                    isLoading: isLoggingIn,
                    element: submitButtonRef.current,
                }}
                onChange={handleLoginFormChange}
                onSubmit={handleSubmit}
                setFormResetHandler={setLoginFormResetHandler}
                onValidnessChange={handleLoginFormValidnessChange}
            >
                {inputProps => (
                    <>
                        <EmailInput {...inputProps} valueKey="email" autoComplete="username" isRequired={true} />
                        <PasswordInput
                            {...inputProps}
                            placeholder="Contraseña"
                            valueKey="password"
                            autoComplete="current-password"
                            isRequired={true}
                        />
                        <SubmitButton
                            ref={submitButtonRef}
                            className={className['submit-button']}
                            onClick={handleSubmit}
                            isDisabled={isLoginFormInvalid}
                            isLoading={isLoggingIn}
                        >
                            Iniciar
                        </SubmitButton>
                    </>
                )}
            </Form>
        </div>
    );
});
