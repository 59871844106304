import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from '../../stores';
import Footer from '../footer/footer';
import Navbar from '../navbar/navbar';
import className from './home.module.scss';
import cx from 'classnames';
import { Outlet } from 'react-router';

type Props = {
    isBlurred: boolean;
    onEventDeselect: () => void;
    onCustomerDeselect: () => void;
    onOrderDeselect: () => void;
};

export default observer(function Home(props: Props) {
    const { userStore, sessionStore, billingStateStore } = useStore();
    const { loggedInUserId } = sessionStore;

    useEffect(() => {
        if (sessionStore.loggedInUserId !== null) {
            flowResult(userStore.show(sessionStore.loggedInUserId)).then(successful => {
                if (successful) {
                    userStore.index();
                    billingStateStore.index();
                }
            });
        }
    }, [loggedInUserId]);

    return (
        <>
            <div
                className={cx(className.base, {
                    [className.blurred]: props.isBlurred,
                })}
            >
                <div className={className.body}>
                    <Outlet />
                </div>
                <Navbar />
                <Footer />
                <div
                    className={className.mask}
                    onClick={() => {
                        props.onEventDeselect();
                        props.onCustomerDeselect();
                        props.onOrderDeselect();
                    }}
                />
            </div>
        </>
    );
});
