import { useEffect, useState, useTransition } from 'react';
import className from './navbar.module.scss';
import cx from 'classnames';
import { useCallbackRef } from '../../util/use-callback-ref';
import UserImage from '../user-image/user-image';
import { useStore } from '../../stores';
import { observer } from 'mobx-react';
import { LuCalendar, LuClipboardPenLine, LuHandshake } from 'react-icons/lu';
import { NavLink, useLocation } from 'react-router';
import { ROUTE } from '../../util/route';

export default observer(function Navbar() {
    const location = useLocation();

    const { userStore, customerStore } = useStore();
    const { user, isShowing: isShowingUser } = userStore;
    const { isCounting: isCountingCustomers } = customerStore;

    const [, startTransition] = useTransition();
    const [selectedIndicatorLeftOffset, setSelectedIndicatorLeftOffset] = useState<number>(0);
    const [selectedIndicatorWidth, setSelectedIndicatorWidth] = useState<number>(0);
    const [selectedIndicatorHeight, setSelectedIndicatorHeight] = useState<number>(0);

    const [selectedActionRef, setSelectedActionRef] = useCallbackRef<HTMLAnchorElement>(selectedActionElement => {
        if (selectedActionElement) {
            const navbarElement = selectedActionElement.parentElement;
            if (navbarElement) {
                startTransition(() => {
                    setSelectedIndicatorLeftOffset(
                        selectedActionElement.getBoundingClientRect().left - navbarElement.getBoundingClientRect().left
                    );
                    setSelectedIndicatorWidth(selectedActionElement.getBoundingClientRect().width);
                    setSelectedIndicatorHeight(selectedActionElement.getBoundingClientRect().height);
                });
            }
        }
    });

    useEffect(() => {
        if (!isShowingUser && !isCountingCustomers) {
            const selectedActionElement = selectedActionRef.current;
            if (selectedActionElement) {
                const navbarElement = selectedActionElement.parentElement;
                if (navbarElement) {
                    setSelectedIndicatorLeftOffset(
                        selectedActionElement.getBoundingClientRect().left - navbarElement.getBoundingClientRect().left
                    );
                    setSelectedIndicatorWidth(selectedActionElement.getBoundingClientRect().width);
                    setSelectedIndicatorHeight(selectedActionElement.getBoundingClientRect().height);
                }
            }
        }
    }, [user, isShowingUser, isCountingCustomers]);

    return (
        <div className={className.base}>
            <div
                className={className['selected-indicator']}
                style={{
                    left: selectedIndicatorLeftOffset - 1,
                    width: selectedIndicatorWidth,
                    height: selectedIndicatorHeight,
                }}
            />
            <NavLink
                to={ROUTE.EVENTS}
                ref={location.pathname.includes(ROUTE.EVENTS) ? setSelectedActionRef : undefined}
                className={({ isActive }) =>
                    cx(className.action, {
                        [className.selected]: isActive,
                    })
                }
            >
                <LuCalendar />
                <div className={className.label}>Recordatorios</div>
            </NavLink>
            <NavLink
                to={ROUTE.CUSTOMERS}
                ref={location.pathname.includes(ROUTE.CUSTOMERS) ? setSelectedActionRef : undefined}
                className={({ isActive }) =>
                    cx(className.action, {
                        [className.selected]: isActive,
                    })
                }
            >
                <LuHandshake />
                <div className={className.label}>Clientes</div>
            </NavLink>
            <NavLink
                to={ROUTE.ORDERS}
                ref={location.pathname.includes(ROUTE.ORDERS) ? setSelectedActionRef : undefined}
                className={({ isActive }) =>
                    cx(className.action, {
                        [className.selected]: isActive,
                    })
                }
            >
                <LuClipboardPenLine />
                <div className={className.label}>Pedidos</div>
            </NavLink>
            {user && (
                <NavLink
                    to={ROUTE.USER}
                    ref={location.pathname.includes(ROUTE.USER) ? setSelectedActionRef : undefined}
                    className={({ isActive }) =>
                        cx(className.action, {
                            [className.selected]: isActive,
                        })
                    }
                >
                    <UserImage user={user} size={20} />
                    <div className={className.label}>Usuario</div>
                </NavLink>
            )}
        </div>
    );
});
