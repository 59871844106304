import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useRef, JSX } from 'react';
import { useStore } from '../../../stores';
import className from './events.module.scss';
import cx from 'classnames';
import UserImage from '../../user-image/user-image';
import { User } from '../../../types/user';
import { Mapped } from '../../../types/mapped';
import EventCard from './event-card';
import { Event } from '../../../types/event';
import Button from '../../button/button';
import Toggle from '../../toggle/toggle';
import { useLocalStorageState } from '../../../util/use-local-storage-state';
import { LOCAL_STORAGE_KEY } from '../../../util/local-storage-key';
import { useCallbackRef } from '../../../util/use-callback-ref';
import { useDebounce } from '../../../util/use-debounce';
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu';

enum MODE {
    ALL = 'all',
    LATEST = 'latest',
    NOT_CONTACTED = 'not-contacted',
}

interface EventsRouteProps {
    selectedEvent: Event | null;
    startOfWeekDateTime: DateTime;
    onEventClick: (event: Event) => void;
    onStartOfWeekDateTimeChange: (startOfWeekDateTime: DateTime) => void;
}

const WEEK_DAYS = 7;

export default observer(function EventsRoute(props: EventsRouteProps) {
    const { userStore, eventStore } = useStore();
    const { users } = userStore;
    const { isIndexing } = eventStore;

    const [mode, setMode] = useLocalStorageState(LOCAL_STORAGE_KEY.EVENTS_ROUTE_MODE, value => value || MODE.LATEST);

    const calendarRef = useRef<HTMLDivElement>(null);
    const firstHeaderRef = useRef<HTMLDivElement>(null);

    const getEventCardId = (event: Event): string => {
        return `event-card-${event.id}`;
    };

    const [, setSelectedEventCardRef] = useCallbackRef<HTMLDivElement>(() => {
        if (props.selectedEvent) {
            const selectedCardEventElement = document.querySelector<HTMLDivElement>(
                `#${getEventCardId(props.selectedEvent)}`
            );
            const calendarElement = calendarRef.current;
            const firstHeaderElement = firstHeaderRef.current;

            if (selectedCardEventElement && calendarElement && firstHeaderElement) {
                const scrollableAreaWidth = calendarElement.offsetWidth - firstHeaderElement.offsetWidth;
                const scrollableAreaHeight = calendarElement.offsetHeight - firstHeaderElement.offsetHeight;
                const scrollableAreaOffsetLeft = calendarElement.offsetLeft + firstHeaderElement.offsetWidth;
                const scrollableAreaOffsetTop = calendarElement.offsetTop + firstHeaderElement.offsetHeight;

                const shouldScrollTop =
                    selectedCardEventElement.offsetTop + selectedCardEventElement.offsetHeight <=
                        scrollableAreaOffsetTop + calendarElement.scrollTop ||
                    selectedCardEventElement.offsetTop >=
                        scrollableAreaOffsetTop + scrollableAreaHeight + calendarElement.scrollTop;
                const shouldScrollLeft =
                    selectedCardEventElement.offsetLeft + selectedCardEventElement.offsetWidth <=
                        scrollableAreaOffsetLeft + calendarElement.scrollLeft ||
                    selectedCardEventElement.offsetLeft >=
                        scrollableAreaOffsetLeft + scrollableAreaWidth + calendarElement.scrollLeft;
                if (shouldScrollTop || shouldScrollLeft) {
                    calendarElement.scrollBy({
                        top: shouldScrollTop
                            ? selectedCardEventElement.offsetTop -
                              (scrollableAreaOffsetTop + calendarElement.scrollTop) -
                              scrollableAreaHeight / 2 +
                              selectedCardEventElement.offsetHeight
                            : undefined,
                        left: shouldScrollLeft
                            ? selectedCardEventElement.offsetLeft -
                              (scrollableAreaOffsetLeft + calendarElement.scrollLeft)
                            : undefined,
                        behavior: 'smooth',
                    });
                }
            }
        }
    }, [props.selectedEvent]);

    useEffect(() => {
        if (props.selectedEvent) {
            const customAtDateTime = DateTime.fromISO(props.selectedEvent.custom_at);
            if (
                customAtDateTime < props.startOfWeekDateTime ||
                customAtDateTime > props.startOfWeekDateTime.plus({ days: 6 })
            ) {
                props.onStartOfWeekDateTimeChange(
                    customAtDateTime.minus({
                        days:
                            (customAtDateTime.weekday < props.startOfWeekDateTime.weekday
                                ? customAtDateTime.weekday + 7
                                : customAtDateTime.weekday) - props.startOfWeekDateTime.weekday,
                    })
                );
            }
        }
    }, [props.selectedEvent]);

    useDebounce(
        () => {
            eventStore.index(props.startOfWeekDateTime, props.startOfWeekDateTime.plus({ days: WEEK_DAYS - 1 }));
        },
        100,
        [props.startOfWeekDateTime]
    );

    const { events, eventsByDay } = useMemo(
        () =>
            eventStore.events.reduce<{ events: Mapped<Mapped<Event[]>>; eventsByDay: Mapped<Event[]> }>(
                ({ events, eventsByDay }, event) => {
                    const eventsByUser = events[event.customer.user_id];

                    events[event.customer.user_id] = eventsByUser
                        ? {
                              ...eventsByUser,
                              [event.custom_at]: [...(eventsByUser[event.custom_at] || []), event],
                          }
                        : {
                              [event.custom_at]: [event],
                          };

                    eventsByDay[event.custom_at] = [...(eventsByDay[event.custom_at] || []), event];

                    return { events, eventsByDay };
                },
                {
                    events: {},
                    eventsByDay: {},
                }
            ),
        [eventStore.events]
    );

    const renderDayProgress = useCallback(
        (dateTime: DateTime, user?: User) => {
            const dayEvents = user
                ? (events[user.id] || {})[dateTime.toFormat('yyyy-MM-dd')] || []
                : eventsByDay[dateTime.toFormat('yyyy-MM-dd')] || [];
            const completedCount = dayEvents.filter(event => event.completed).length;
            const completedPercentage =
                dayEvents.length > 0 ? `${Math.round((10000 * completedCount) / dayEvents.length) / 100}%` : null;

            return (
                <div
                    className={cx(className.progress, {
                        [className.shown]: completedPercentage !== null || isIndexing,
                    })}
                >
                    {user && (
                        <div className={className.label}>
                            Progreso de <b>hoy</b>
                        </div>
                    )}
                    <div className={className['progress-bar']}>
                        <div className={className['progress-indicator']} style={{ width: completedPercentage || 0 }} />
                    </div>
                    <div className={className.count}>
                        <div className={className.completed}>{completedCount}</div>
                        <div className={className.all}>de {dayEvents.length}</div>
                    </div>
                </div>
            );
        },
        [isIndexing, events, eventsByDay]
    );

    const renderDayUserEvents = useCallback(
        (user: User, dayDateTime: DateTime) => {
            const userEvents = events[user.id];
            const dayUserEvents = userEvents ? userEvents[dayDateTime.toFormat('yyyy-MM-dd')] : null;

            return dayUserEvents ? (
                dayUserEvents.map(event => {
                    const eventCardId = getEventCardId(event);
                    const isSelected = props.selectedEvent && props.selectedEvent.id === event.id;

                    return (
                        <EventCard
                            key={eventCardId}
                            ref={isSelected ? setSelectedEventCardRef : null}
                            event={event}
                            isShown={
                                mode === MODE.ALL ||
                                (mode === MODE.LATEST && event.latest) ||
                                (mode === MODE.NOT_CONTACTED && !event.completed)
                            }
                            id={eventCardId}
                            className={cx(className['event-card'], {
                                [className.selected]: isSelected,
                            })}
                            onClick={event => props.onEventClick(event)}
                        />
                    );
                })
            ) : (
                <></>
            );
        },
        [events, mode, props.selectedEvent]
    );

    const handleNextButtonClick = () => {
        props.onStartOfWeekDateTimeChange(props.startOfWeekDateTime.plus({ week: 1 }));
    };

    const handlePreviousButtonClick = () => {
        props.onStartOfWeekDateTimeChange(props.startOfWeekDateTime.minus({ week: 1 }));
    };

    const handleTodayButtonClick = () => {
        props.onStartOfWeekDateTimeChange(DateTime.now().minus({ days: 3 }));
    };

    const renderCalendar = useCallback(() => {
        const todayDateTime = DateTime.now();

        const headers: JSX.Element[] = [];
        const cells: JSX.Element[] = [];
        for (let userIndex = 0; userIndex <= users.length; userIndex++) {
            const user = userIndex > 0 ? users[userIndex - 1] : null;
            const userName = user
                ? user.name.split(' ').reduce((userName, namePart) => `${userName} ${namePart.substr(0, 1)}.`)
                : '';

            for (let dayIndex = 0; dayIndex <= WEEK_DAYS; dayIndex++) {
                const dayDateTime = dayIndex > 0 ? props.startOfWeekDateTime.plus({ days: dayIndex - 1 }) : null;

                if (userIndex === 0) {
                    headers.push(
                        <div
                            ref={dayDateTime === null ? firstHeaderRef : null}
                            key={`header-${dayIndex}`}
                            className={cx(className.header, {
                                [className.today]: dayDateTime ? todayDateTime.hasSame(dayDateTime, 'day') : false,
                            })}
                            style={{ borderLeft: dayIndex === 1 ? 0 : undefined }}
                        >
                            {dayDateTime && (
                                <>
                                    <div className={className['day-label']}>{dayDateTime.toFormat('EEEE')}</div>
                                    <div className={className['day-number']}>{dayDateTime.day}</div>
                                    {renderDayProgress(dayDateTime)}
                                </>
                            )}
                        </div>
                    );
                } else {
                    cells.push(
                        dayDateTime ? (
                            user ? (
                                <div
                                    key={`cell-${user.id - 1}-${dayIndex - 1}`}
                                    className={cx(className.cell, {
                                        [className.today]: dayDateTime
                                            ? todayDateTime.hasSame(dayDateTime, 'day')
                                            : false,
                                    })}
                                    style={{
                                        borderLeft: dayIndex === 1 ? 0 : undefined,
                                        borderTop: user.id < 0 ? 0 : undefined,
                                    }}
                                >
                                    {renderDayUserEvents(user, dayDateTime)}
                                </div>
                            ) : (
                                <></>
                            )
                        ) : (
                            <div
                                key={`user-cell-${(user ? user.id : userIndex) - 1}`}
                                className={className['user-cell']}
                                style={{ borderTop: user && user.id < 0 ? 0 : undefined }}
                            >
                                {user && (
                                    <div
                                        className={cx(className.user, {
                                            [className['no-image']]: user.id < 0,
                                        })}
                                    >
                                        <UserImage user={user} size={28} className={className.image} />
                                        <div
                                            className={className.details}
                                            style={user.id < 0 ? { gridTemplateRows: '15px' } : undefined}
                                        >
                                            <div className={className.name}>{user.id >= 0 ? userName : user.name}</div>
                                            {user.id >= 0 && <div className={className.id}>ID: {user.id}</div>}
                                        </div>
                                        {renderDayProgress(DateTime.now(), user)}
                                    </div>
                                )}
                            </div>
                        )
                    );
                }
            }
        }

        return (
            <div ref={calendarRef} className={className.calendar}>
                {headers}
                {cells}
            </div>
        );
    }, [users, props.startOfWeekDateTime, eventsByDay, renderDayUserEvents, renderDayProgress]);

    const renderDate = useCallback(() => {
        const endOfWeekDateTime = props.startOfWeekDateTime.endOf('week');
        return `${props.startOfWeekDateTime.toFormat('MMMM')}${
            endOfWeekDateTime.get('month') !== props.startOfWeekDateTime.get('month')
                ? ` - ${endOfWeekDateTime.toFormat('MMMM')}`
                : ''
        } ${endOfWeekDateTime.toFormat('yyyy')}`;
    }, [props.startOfWeekDateTime]);

    return (
        <div className={className.base}>
            <div className={className.toolbar}>
                <div className={className.buttons}>
                    <Button className={cx(className.button, className.squared)} onClick={handlePreviousButtonClick}>
                        <LuChevronLeft size={20} />
                    </Button>
                    <Button className={cx(className.button, className.squared)} onClick={handleNextButtonClick}>
                        <LuChevronRight size={20} />
                    </Button>
                    <Button
                        className={className.button}
                        onClick={handleTodayButtonClick}
                        isDisabled={props.startOfWeekDateTime.hasSame(DateTime.now().minus({ days: 3 }), 'day')}
                    >
                        Hoy
                    </Button>
                </div>
                <div className={className.date}>{renderDate()}</div>
                <Toggle
                    className={className.toggle}
                    value={mode}
                    options={[
                        {
                            value: MODE.ALL,
                            label: 'Todos',
                        },
                        {
                            value: MODE.LATEST,
                            label: 'Últimos',
                        },
                        {
                            value: MODE.NOT_CONTACTED,
                            label: 'Sin haber contactado',
                        },
                    ]}
                    onChange={setMode}
                />
            </div>
            {renderCalendar()}
        </div>
    );
});
