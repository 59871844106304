import { cloneElement, useEffect, useRef, useState, JSX } from 'react';
import { COLOR } from '../../util/color';
import parse from 'html-react-parser';
import className from './context-message.module.scss';
import cx from 'classnames';
import { LuCircleCheck, LuCircleX } from 'react-icons/lu';

const ICON_SIZE = 18;

declare global {
    interface Window {
        contextMessageController: {
            onSuccessShow: (message: string) => void;
            onFailShow: (message: string) => void;
        };
    }
}

export default function ContextMessage() {
    const timeoutId = useRef<number>(undefined);
    const [message, setMessage] = useState<string | null>(null);
    const [icon, setIcon] = useState<{
        icon: JSX.Element;
        color?: COLOR;
        isEmoji?: true;
    } | null>(null);
    const [isShown, setIsShown] = useState<boolean>(false);
    const [messageWidth, setMessageWidth] = useState<number>(ICON_SIZE);

    const textRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        window.contextMessageController = {
            onSuccessShow: handleSuccessShow,
            onFailShow: handleFailShow,
        };
    }, []);

    useEffect(() => {
        const timeoutId = window.setTimeout(() => {
            if (isShown) {
                if (textRef.current) {
                    setMessageWidth(ICON_SIZE + 24 + textRef.current.getBoundingClientRect().width);
                }
            }
        }, 300);

        return () => window.clearTimeout(timeoutId);
    }, [isShown]);

    const hide = () => {
        setMessageWidth(ICON_SIZE);

        window.setTimeout(() => {
            setIsShown(false);

            window.setTimeout(() => {
                setMessage(null);
            }, 300);
        }, 200);
    };

    useEffect(() => {
        if (message) {
            if (isShown) {
                window.clearTimeout(timeoutId.current);
            } else {
                setIsShown(true);
            }

            timeoutId.current = window.setTimeout(() => {
                hide();
            }, 5000);
        }

        return () => window.clearTimeout(timeoutId.current);
    }, [message, icon]);

    const handleSuccessShow = (message: string) => {
        setMessage(message);
        setIcon({
            icon: <LuCircleCheck />,
            color: COLOR.GREEN_500,
        });
    };

    const handleFailShow = (message: string) => {
        setMessage(message);
        setIcon({
            icon: <LuCircleX />,
            color: COLOR.RED_300,
        });
    };

    return (
        <div
            className={cx(className['context-message'], {
                [className.shown]: isShown,
            })}
        >
            <div className={className.message} style={{ maxWidth: messageWidth }}>
                {icon &&
                    (icon.isEmoji ? (
                        <div className={className.emoji}>{icon.icon}</div>
                    ) : (
                        cloneElement(icon.icon, { color: icon.color, size: 18, style: { flexShrink: 0 } })
                    ))}
                <div ref={textRef} className={className.text}>
                    {parse(message ?? '')}
                </div>
            </div>
        </div>
    );
}
