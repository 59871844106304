import { makeAutoObservable, toJS } from 'mobx';
import { Store } from '../types/store';
import { User } from '../types/user';
import { UserForm } from '../types/user-form';
import { getMappedObjects } from '../util/get-mapped-objects';
import { NO_USER } from '../util/no-user';
import { Http, HttpResponse } from './http';
import SessionStore from './session';

export default class UserStore implements Store {
    private http: Http;
    user: User | null;
    private _users: User[];
    isShowing = false;
    isIndexing = false;
    isUpdating = false;
    isUpdatingImage = false;

    constructor(sessionStore: SessionStore) {
        this.http = new Http(sessionStore);
        this.user = null;
        this._users = [];

        makeAutoObservable(this);
    }

    get path() {
        return 'users';
    }

    get users() {
        return [NO_USER, ...this._users];
    }

    get mappedUsers() {
        return getMappedObjects(toJS(this.users));
    }

    *show(userId: User['id']) {
        this.isShowing = true;

        const response: HttpResponse<User> = yield this.http.getApi<User>(`${this.path}/${userId}`);

        if (response.successful && response.data) {
            this.user = response.data;

            window.contextMessageController.onSuccessShow(`¡Hola <b>${this.user.name}</b>!`);
        }

        this.isShowing = false;

        return response.successful;
    }

    *index() {
        this.isIndexing = true;

        const response: HttpResponse<User[]> = yield this.http.getApi<User[]>(this.path);

        if (response.successful && response.data) {
            this._users = response.data;
        }

        this.isIndexing = false;

        return response.successful;
    }

    *update({ id: userId, password, ...userParams }: UserForm) {
        this.isUpdating = true;

        const params = password ? { ...userParams, password } : userParams;

        const response: HttpResponse<User> = yield this.http.putApi<User>(`${this.path}/${userId}`, params);

        if (response.successful && response.data) {
            this.user = { ...this.user, ...userParams, ...response.data };
            this._users = this._users.map(user => (user.id === userId ? this.user! : user));

            window.contextMessageController.onSuccessShow('se actualizó la información de tu usuario');
        }

        this.isUpdating = false;

        return response.successful;
    }

    *updateImage(userId: User['id'], image: File) {
        this.isUpdatingImage = true;

        const response: HttpResponse<User> = yield this.http.fileApi<User>(`users-image/${userId}`, image);

        if (response.successful && response.data) {
            this.user = { ...this.user, ...response.data };
            this._users = this._users.map(user => (user.id === userId ? this.user! : user));
        }

        this.isUpdatingImage = false;

        return response.successful;
    }
}
