import className from './toggle.module.scss';
import cx from 'classnames';
import { useCallbackRef } from '../../util/use-callback-ref';
import { useState, useTransition } from 'react';

interface ToggleProps<T> {
    value: T;
    options: { value: T; label: string }[];
    className?: string;
    onChange: (optionValue: T) => void;
}

export default function Toggle<T extends string | number | boolean>(props: ToggleProps<T>) {
    const [, startTransition] = useTransition();
    const [selectedIndicatorLeftOffset, setSelectedIndicatorLeftOffset] = useState<number>(0);
    const [selectedIndicatorWidth, setSelectedIndicatorWidth] = useState<number>(0);
    const [selectedIndicatorHeight, setSelectedIndicatorHeight] = useState<number>(0);

    const [, setSelectedOptionRef] = useCallbackRef<HTMLDivElement>(selectedActionElement => {
        if (selectedActionElement) {
            const toggleElement = selectedActionElement.parentElement;
            if (toggleElement) {
                startTransition(() => {
                    setSelectedIndicatorLeftOffset(
                        selectedActionElement.getBoundingClientRect().left - toggleElement.getBoundingClientRect().left
                    );
                    setSelectedIndicatorWidth(selectedActionElement.getBoundingClientRect().width);
                    setSelectedIndicatorHeight(selectedActionElement.getBoundingClientRect().height);
                });
            }
        }
    });

    return (
        <div className={cx(className.base, props.className)}>
            <div
                className={className['selected-indicator']}
                style={{
                    left: selectedIndicatorLeftOffset - 1,
                    width: selectedIndicatorWidth,
                    height: selectedIndicatorHeight,
                }}
            />
            {props.options.map(option => {
                const isSelected = props.value === option.value;

                return (
                    <div
                        ref={isSelected ? setSelectedOptionRef : undefined}
                        key={`${className.option}-${option.value}`}
                        className={cx(className.option, {
                            [className.selected]: isSelected,
                        })}
                        onClick={!isSelected ? () => props.onChange(option.value) : undefined}
                    >
                        {option.label}
                    </div>
                );
            })}
        </div>
    );
}
